//Required imports
import { useEffect, useState } from "react";
import { Header } from "../Header/Header";
import { BsArrowLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import Alert from "../../assets/alert.png";
import Percentage from "../../assets/percentage.png";
import Timer from "../../assets/time.png";
import { message, Spin } from "antd";
import { Button } from "../Button/Button";
import { clearBankDetails } from "../../redux/bank/bankSlice";
import { useDispatch, useSelector } from "react-redux";
import { checkPaymentStatus } from "../../redux/paying/payingSlice";
import Copy from "../../assets/copy.png";

//Styling
import "antd/dist/antd.css";
import "./Bank.css";

export const Bank = () => {
  const [delayed, setDelayed] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const bank = useSelector((state) => state.bank);
  const recipient = useSelector((state) => state.recipient);
  const sender = useSelector((state) => state.sender);
  const { payingDetails } = useSelector((state) => state.paying);

  //Account expiry
  const calculateTimeLeft = () => {
    const difference = bank.bankLoadDetails
      ? Math.abs(new Date() - new Date(bank.bankLoadDetails.expiresAt))
      : "";
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        hours: Math.floor(difference / (1000 * 60 * 60)),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  useEffect(() => {
    if (timeLeft.hours && timeLeft.minutes && timeLeft.seconds <= 0) {
      navigate(`/${recipient.recipientDetails.tag}/pay/expired`);
      dispatch(clearBankDetails());
    }
  }, [timeLeft]);

  //Capitalize
  const capitalizeFirst = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  //Header
  const headerMessage = bank.bankLoadDetails
    ? `Pay ${sender.currency} ${sender.senderDetails.amount} into the account number below.`
    : "";

  //Check payment status
  let checkPaymentStatusInput = {
    accountReference: bank.bankLoadDetails
      ? bank.bankLoadDetails.reference
      : "",
    username: recipient.recipientDetails.email,
  };

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(checkPaymentStatus(checkPaymentStatusInput));
    }, 60000);

    const timeout = setTimeout(() => {
      clearInterval(interval);
      setDelayed(true);
    }, 15 * 60 * 1000);

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, [bank.bankLoadDetails]);

  useEffect(() => {
    if (payingDetails?.toppedUp) {
      navigate(`/${recipient.recipientDetails.tag}/pay/complete`);
      dispatch(clearBankDetails());
    }
  }, [payingDetails]);

  //Go back
  const goBack = () => {
    dispatch(clearBankDetails());
  };

  useEffect(() => {
    if (!bank.bankLoadDetails) {
      navigate(-1);
    }
  }, bank.bankLoadDetails);

  //Copy account number

  const CopyAccount = () => {
    if (bank?.bankLoadDetails) {
      navigator.clipboard.writeText(bank.bankLoadDetails.accountNumber);
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 1500);
    }
  };

  useEffect(() => {
    if (isCopied) {
      message.success("Acount number copied!");
    }
  }, [isCopied]);

  return (
    <div>
      <Header />

      <div className="page">
        <div className="container">
          <div className="back" onClick={() => goBack()}>
            <BsArrowLeft style={{ color: "#4B487A", fontSize: "20px" }} />
            <span>Back</span>
          </div>

          {bank.bankLoadDetails && (
            <div className="profile bank">
              <div className="bank-header">
                <h3>{headerMessage}</h3>
              </div>

              <div className="timer">
                <p>This account is temporary and expires in </p>
                <p>
                  <span>{timeLeft.minutes} </span>
                  Mins
                  <span> : </span>
                  <span>{timeLeft.seconds} </span>
                  Secs
                </p>
              </div>

              {bank.bankLoadDetails && (
                <div className="bank-details">
                  <div>
                    <p>Account Name</p>
                    <p>{bank.bankLoadDetails.accountName}</p>
                  </div>

                  <div className="account-number">
                    <p>Account number</p>
                    <div>
                      <p>{bank.bankLoadDetails.accountNumber}</p>
                      <p onClick={CopyAccount}>
                        {isCopied ? (
                          <img className="copy" src={Copy} alt="copy" />
                        ) : (
                          <img className="copy" src={Copy} alt="copy" />
                        )}
                      </p>
                    </div>
                  </div>

                  <div>
                    <p>Bank name</p>
                    <p>
                      {capitalizeFirst(bank.bankLoadDetails.bankName) + " bank"}
                    </p>
                  </div>
                </div>
              )}

              <div className="warning">
                <div>
                  <img src={Alert} alt="" />
                  <p>Minimum deposit amount of NGN 100</p>
                </div>
                {/* <div>
                  <img src={Percentage} alt="" />
                  <p>
                    You will incur 0.3% fees (capped at a minimum of GBP 5 and a
                    maximum of GBP 15) for this service.
                  </p>
                </div> */}
                <div>
                  <img src={Timer} alt="" />
                  <p>Transfers usually arrives within 24 hours</p>
                </div>
              </div>

              <div className="timer bank-spinner">
                {delayed ? (
                  <Button
                    btncontent="Contact customer service"
                    id="customer_service"
                  />
                ) : (
                  <Spin size="large" />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
