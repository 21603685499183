import { BsArrowLeft } from "react-icons/bs";
import { Header } from "../Header/Header";
import SearchTag from "../../assets/search.png";
import { Button } from "../Button/Button";
import { Input, Space } from "antd";

//Styling
import "antd/dist/antd.css";
import "./CreateTransaction.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const CreateTransaction = () => {
  const [fulltag, setFulltag] = useState("");
  const navigate = useNavigate();

  //Handle change
  const handleChange = (e) => {
    setFulltag(e.target.value);
  };

  //Handle onClick
  const onBtnClick = (e) => {
    e.preventDefault();

    if (fulltag) {
      navigate(`/${fulltag}`);
    }
  };

  return (
    <div>
      <Header />
      <div className="page">
        <div className="container">
          <div className="back" onClick={() => navigate(-1)}>
            <BsArrowLeft style={{ color: "#4B487A", fontSize: "20px" }} />
            <span>Back</span>
          </div>

          <div className="profile create-transaction">
            <img src={SearchTag} alt="" />
            <h3>Eversend Tags</h3>

            <div>
              <Space direction="vertical">
                <label>Enter Eversend tag</label>
                <Input
                  addonBefore="https://eversend.me/"
                  placeholder="john"
                  onChange={handleChange}
                />
              </Space>
              <Button
                btncontent="Continue"
                onClick={onBtnClick}
                style={{ backgroundColor: fulltag ? "#5A50FE" : "" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
