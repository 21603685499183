//Required imports
import { Home } from './components/Home/Home';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Profile } from './components/Profile/Profile';
import { Unsupported } from './components/Unsupported/Unsupported';
import { CreateTransaction } from './components/CreateTransaction/CreateTransaction';
import { PhoneNumber } from './components/PhoneNumber/PhoneNumber';
import { Otp } from './components/Otp/Otp';
import { Confirm } from './components/Confirm/Confirm';
import { Bank } from './components/Bank/Bank';
import { Expired } from './components/Expired/Expired';
import { Complete } from './components/Complete/Complete';
import { Paying } from './components/Paying/Paying';
import { Pending } from './components/Pending/Pending';
import { OtpConfirmation } from './components/OtpConfirmation/OtpConfirmation';

//Styling
import './css/Normalize.css';
import './css/App.css';

function App() {
  return (
    <div className='App'>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/:tag' element={<Profile />} />
          <Route path='/:tag/pay/unsupported' element={<Unsupported />} />
          <Route path='/:tag/createtransaction' element={<CreateTransaction />} />
          <Route path='/:tag/pay/phone' element={<PhoneNumber />} />
          <Route path='/:tag/pay/otp' element={<Otp />} />
          <Route path='/:tag/pay/confirm' element={<Confirm />} />
          <Route path='/:tag/pay/otp-confirmation' element={<OtpConfirmation />} />
          <Route path='/:tag/pay/pending' element={<Pending />} />
          <Route path='/:tag/pay/complete' element={<Complete />} />
          <Route path='/:tag/pay/bank' element={<Bank />} />
          <Route path='/:tag/pay/expired' element={<Expired />} />
          <Route path='/:tag/pay/paying' element={<Paying />} />
          <Route path='*' element={<Home />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
