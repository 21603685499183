import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API } from "../../firebase/firebase";

const initialState = {
  loading: false,
  bankLoadDetails: null,
  error: "",
};

export const loadBankWallet = createAsyncThunk(
  "wallet/loadBank",
  async (loadInput) => {
    const { username, amount, paymentMethodId, currency } = loadInput;

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        username,
        amount,
        paymentMethodId,
        currency,
      }),
    };

    try {
      const res = await fetch(`${API.baseURL}/loadWalletBank`, requestOptions);
      if (!res.ok) {
        throw new Error(res.statusText);
      }
      const data = await res.json();
      return data.data;
    } catch (err) {
      throw err;
    }
  }
);

const bankSlice = createSlice({
  name: "bank",
  initialState,
  reducers: {
    clearBankError: (state) => {
      state.error = "";
    },
    clearBankDetails: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(loadBankWallet.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(loadBankWallet.fulfilled, (state, action) => {
      state.loading = false;
      state.bankLoadDetails = action.payload;
      state.error = "";
    });
    builder.addCase(loadBankWallet.rejected, (state, action) => {
      state.loading = false;
      state.bankLoadDetails = null;
      state.error = action.error.name;
    });
  },
});

export const { clearBankError, clearBankDetails } = bankSlice.actions;
export default bankSlice.reducer;
