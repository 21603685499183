import { BsTags } from "react-icons/bs";
import Avatar from "../../assets/avatar.webp";
import _ from "lodash";

//Styling
import "./ProfileIntro.css";

export const ProfileIntro = ({ avatar, recipient, tag }) => {
  return (
    <div className="profile-intro">
      <img src={avatar || Avatar} alt="avatar" />
      <h3>{_.startCase(recipient.toLowerCase()) || "Eversend User"}</h3>
      <p>
        <span>
          <BsTags style={{ color: "#6b6b80" }} />
        </span>
        <span>{tag || "https://eversend.me/eversenduser"}</span>
      </p>
    </div>
  );
};
