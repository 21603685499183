//Required imports
import { useEffect, useState } from 'react';
import { Header } from '../Header/Header';
import { ProfileIntro } from '../ProfileIntro/ProfileIntro';
import Avatar from '../../assets/avatar.webp';
import { Divider } from '../Divider/Divider';
import { Footer } from '../Footer/Footer';
import { Amount, Error } from '../Amount/Amount';
import { Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { addFullTag, clearRecipientDetails, fetchRecipientDetails } from '../../redux/recipient/recipientSlice';
import { resetSenderState } from '../../redux/sender/senderSlice';
import { clearVerificationDetails } from '../../redux/otp/otpSlice';
import { clearPayingDetails } from '../../redux/paying/payingSlice';
import { clearMomoLoadDetails } from '../../redux/transaction/transactionSlice';
import { clearBankDetails } from '../../redux/bank/bankSlice';
import Alert from '../../assets/alert.png';
import { QRCodeSVG } from 'qrcode.react';

//Styling
import './Profile.css';
import 'antd/dist/antd.css';

export const Profile = () => {
  const [err, setError] = useState();

  //Get tag from URL
  const url = window.location.pathname.split('/');
  const tag = url[1];
  const fulltag = `https://eversend.me/${tag}`;

  //Redux state
  const dispatch = useDispatch();
  const recipient = useSelector((state) => state.recipient);

  useEffect(() => {
    dispatch(resetSenderState());
    dispatch(clearVerificationDetails());
    dispatch(clearPayingDetails());
    dispatch(clearMomoLoadDetails());
    dispatch(clearBankDetails());
    dispatch(fetchRecipientDetails(tag));
    dispatch(addFullTag(fulltag));
  }, [tag, dispatch, fulltag]);

  useEffect(() => {
    if (recipient.error) {
      setError('Could not find tag owner!');
      dispatch(clearRecipientDetails());
    }
  }, [dispatch, recipient.error]);

  return (
    <div>
      <Header />

      <div className='page'>
        <div className='container'>
          {recipient.loading ? (
            <div className='page-loader'>
              <Spin size='large' />
            </div>
          ) : !recipient.loading && recipient.recipientDetails && !recipient.recipientDetails.verified ? (
            <div className='profile unverified'>
              <div className='profile-container'>
                {err ? <Error err={err} /> : ''}

                {!recipient.loading && recipient.recipientDetails ? (
                  <ProfileIntro
                    avatar={recipient.recipientDetails.avatarURL || Avatar}
                    recipient={recipient.recipientDetails.firstName + ' ' + recipient.recipientDetails.lastName}
                    tag={fulltag}
                  />
                ) : null}
              </div>

              <div className='unverified-alert'>
                <img src={Alert} alt='alert icon' />
                <p>
                  {`Sorry, we can't process your transfer to ${recipient.recipientDetails.firstName} now, as their account is unverified. Kindly prompt them to submit verification documents for seamless transactions and enable them to receive funds from you.`}
                </p>
              </div>

              <div className='unverified-button'>
                <a href='https://eversend.co'>Go to Eversend</a>
              </div>
            </div>
          ) : (
            <div className='profile'>
              <div className='profile-container'>
                {err ? <Error err={err} /> : ''}

                {!recipient.loading && recipient.recipientDetails ? (
                  <ProfileIntro
                    avatar={recipient.recipientDetails.avatarURL || Avatar}
                    recipient={recipient.recipientDetails.firstName + ' ' + recipient.recipientDetails.lastName}
                    tag={fulltag}
                  />
                ) : null}
              </div>

              <div className='amount-container'>
                <Amount />
              </div>
              <div>
                <Divider />
              </div>

              <div className='qr-container'>{recipient.recipientDetails && <QRCodeSVG value={fulltag} />}</div>

              <div>
                <Footer />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
