//Styling
import "./Divider.css";
export const Divider = () => {
  return (
    <div className="divider">
      <div className="line"></div>
      <p>Or scan QR code to send from your Eversend app</p>
      <div className="line"></div>
    </div>
  );
};
