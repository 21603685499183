import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API } from "../../firebase/firebase";

const initialState = {
  loading: false,
  senderDetails: null,
  currency: "",
  phoneNumber: "",
  otpDetails: null,
  error: "",
};

export const sendOtp = createAsyncThunk("otp/sendOtp", async (otpInput) => {
  const { username, phoneNumber, phone, phonePrefix, channel } = otpInput;
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      username,
      phoneNumber,
      phone,
      phonePrefix,
      channel,
    }),
  };

  try {
    const res = await fetch(`${API.baseURL}/sendOtp`, requestOptions);
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    const data = await res.json();
    return data.data;
  } catch (err) {
    throw err;
  }
});

const senderSlice = createSlice({
  name: "sender",
  initialState,
  reducers: {
    addSenderData: (state, action) => {
      state.senderDetails = action.payload;
    },
    addCurrency: (state, action) => {
      state.currency += action.payload;
    },

    addPhoneNumber: (state, action) => {
      state.phoneNumber += action.payload;
    },

    clearOtpDetails: (state) => {
      state.otpDetails = null;
    },

    clearPhoneNumber: (state) => {
      state.phoneNumber = "";
    },

    clearOtpError: (state) => {
      state.error = "";
    },

    resetSenderState: () => initialState,
  },

  extraReducers: (builder) => {
    builder.addCase(sendOtp.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(sendOtp.fulfilled, (state, action) => {
      state.loading = false;
      state.otpDetails = action.payload;
      state.error = "";
    });
    builder.addCase(sendOtp.rejected, (state, action) => {
      state.loading = false;
      state.otpDetails = null;
      state.error = action.error.name;
    });
  },
});

export default senderSlice.reducer;
export const {
  addSenderData,
  addCurrency,
  addPhoneNumber,
  clearOtpDetails,
  resetSenderState,
  clearPhoneNumber,
  clearOtpError,
} = senderSlice.actions;
