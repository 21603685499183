import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API } from "../../firebase/firebase";

const initialState = {
  loading: false,
  momoLoadDetails: null,
  error: "",
};

export const loadMomoWallet = createAsyncThunk(
  "wallet/loadMomo",
  async (loadInput) => {
    const {
      username,
      amount,
      paymentMethodId,
      currency,
      pinId,
      senderFirstName,
      senderLastName,
      redirectUrl,
      note,
    } = loadInput;

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        username,
        amount,
        paymentMethodId,
        currency,
        pinId,
        senderFirstName,
        senderLastName,
        redirectUrl,
        note,
      }),
    };

    try {
      const res = await fetch(`${API.baseURL}/loadWalletMomo`, requestOptions);
      if (!res.ok) {
        throw new Error(res.statusText);
      }
      const data = await res.json();
      return data.data;
    } catch (err) {
      throw err;
    }
  }
);

const confirmMomoSlice = createSlice({
  name: "receipient",
  initialState,
  reducers: {
    clearMomoLoadDetails: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(loadMomoWallet.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(loadMomoWallet.fulfilled, (state, action) => {
      state.loading = false;
      state.momoLoadDetails = action.payload;
      state.error = "";
    });
    builder.addCase(loadMomoWallet.rejected, (state, action) => {
      state.loading = false;
      state.momoLoadDetails = null;
      state.error = action.error.message;
    });
  },
});

export const { clearMomoLoadDetails } = confirmMomoSlice.actions;

export default confirmMomoSlice.reducer;
