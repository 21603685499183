import { useNavigate } from "react-router-dom";
import { Button } from "../Button/Button";
import { Header } from "../Header/Header";
import Prompt from "../../assets/prompt.png";
import { useSelector } from "react-redux";

//Styling
import "./Pending.css";

export const Pending = () => {
  const navigate = useNavigate();
  const sender = useSelector((state) => state.sender);
  const { recipientDetails } = useSelector((state) => state.recipient);
  const { momoLoadDetails } = useSelector((state) => state.loadmomo);
  const myCustomMessage = `Hi, there! My name is ${sender.senderDetails.firstName}. \nI would like to know why my tag payment to ${recipientDetails.firstName} ${recipientDetails.lastName} is still pending. \nTag: ${recipientDetails.tag} \nAmount: ${sender.senderDetails.amount} \nLoad reference: ${momoLoadDetails.reference}. `;
  window.Intercom("showNewMessage", myCustomMessage);

  return (
    <div>
      <Header />

      <div className="page">
        <div className="container">
          <div className="profile success">
            {
              <div className="success-top pending-top">
                <img src={Prompt} alt="" />
                {sender.senderDetails && (
                  <h3>{`Your payment of ${sender.currency} ${sender.senderDetails.amount.toLocaleString()} to ${
                    recipientDetails.firstName
                  } is taking too long to process!`}</h3>
                )}
              </div>
            }

            <Button
              btncontent="Make another payment"
              onClick={() => {
                navigate(`/${recipientDetails.tag}`);
              }}
            ></Button>

            <div className="link-container">
              <Button
                btncontent="Contact customer service"
                id="customer_service"
                style={{ backgroundColor: "#F0F0F0", color: "#5A50FE" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
