//Required imports
import React from "react";
import { Link } from "react-router-dom";
import { Button } from "../Button/Button";
import { Header } from "../Header/Header";
import { ProfileIntro } from "../ProfileIntro/ProfileIntro";
import { useSelector } from "react-redux";

//Styling
import "./Expired.css";

export const Expired = () => {
  const recipient = useSelector((state) => state.recipient);
  const sender = useSelector((state) => state.sender);

  return (
    <div>
      <div>
        <Header />
      </div>

      <div className="profile">
        <div className="expired-top">
          <h3>Bank account expired</h3>
          <p>The bank account for your transaction exceeded its valid time.</p>
        </div>

        <div className="profile-container">
          <ProfileIntro
            recipient={
              recipient.recipientDetails.firstName +
              " " +
              recipient.recipientDetails.lastName
            }
            tag={recipient.fulltag}
          />
        </div>

        <div className="extra-content expired-content">
          <div>
            <p>Transaction amount</p>
            <p>{sender.currency + " " + sender.senderDetails.amount}</p>
          </div>

          <div>
            <p>Receiving wallet</p>
            <p>{sender.currency} wallet</p>
          </div>
        </div>

        <div>
          <Link to="/pay/createtransaction">
            <Button btncontent="Creat new account number" />
          </Link>
        </div>

        <div className="expired-bottom">
          <p id="customer_service">I have made payments</p>
          <p>{`*Only click on “I have made payments” If you have made payments to the account above. `}</p>
        </div>
      </div>
    </div>
  );
};
