//Required imports
import { useEffect, useState } from 'react';
import { Header } from '../Header/Header';
import { BsArrowLeft } from 'react-icons/bs';
import OtpInput from 'react-otp-input';
import { useNavigate } from 'react-router-dom';
import { Error } from '../Amount/Amount';
import { message, Button } from 'antd';
import { API } from '../../firebase/firebase';
import { useDispatch, useSelector } from 'react-redux';
import { clearVerificationError, verifyOtp } from '../../redux/otp/otpSlice';
import { fetchCharges } from '../../redux/charges/chargesSlice';
import { clearOtpDetails, clearPhoneNumber, sendOtp } from '../../redux/sender/senderSlice';

//Styling
import 'antd/dist/antd.css';
import '../Amount/Amount.css';
import './Otp.css';

export const Otp = () => {
  const [otp, setOtp] = useState('');
  const sender = useSelector((state) => state.sender);
  const recipient = useSelector((state) => state.recipient);
  const verifyotp = useSelector((state) => state.verifyotp);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //Handle change
  const handleChange = (otp) => {
    setOtp(otp);
  };

  //Verify Otp
  const username = recipient.recipientDetails ? recipient.recipientDetails.email : '';

  const pinId = sender.otpDetails ? sender.otpDetails.pinId : '';
  const verifyOtpInput = { pin: otp, username, pinId };

  const paymentMethodId = recipient.recipientDetails
    ? recipient.recipientDetails.momoWallets.includes(sender.currency)
      ? API.momoId
      : API.bankId
    : '';

  const chargesInput = {
    username,
    paymentMethodId,
    currency: sender.currency,
    amount: sender.senderDetails.amount,
  };

  const otpBtnClick = (e) => {
    e.preventDefault();
    dispatch(verifyOtp(verifyOtpInput));
    dispatch(fetchCharges(chargesInput));
  };

  useEffect(() => {
    if (verifyotp.verificationDetails) {
      navigate(`/${recipient.recipientDetails.tag}/pay/confirm`);
    }
  }, [verifyotp.verificationDetails]);

  useEffect(() => {
    if (verifyotp.error !== '') {
      setOtp('');
      message.error('Wrong otp entered!');
      dispatch(clearVerificationError());
    }
  }, [verifyotp.error]);

  //Resend Otp

  const otpInput = { phoneNumber: sender.phoneNumber, username };

  const resendOtp = (e) => {
    e.preventDefault();
    dispatch(sendOtp(otpInput));
    message.success('Otp has been resend!');
  };

  //Back btn logic

  const goBack = () => {
    dispatch(clearOtpDetails());
    dispatch(clearPhoneNumber());
    navigate(-1);
  };

  return (
    <div>
      <Header />
      <div className='page'>
        <div className='container'>
          <div>
            <div className='back' onClick={goBack}>
              <BsArrowLeft style={{ color: '#4B487A', fontSize: '20px' }} />
              <span>Back</span>
            </div>

            <div className='otp'>
              <h3>Enter OTP</h3>
              <p>To confirm this transaction. Enter the OTP code we sent to your phone number.</p>

              <div className='otp-input'>
                <OtpInput
                  value={otp}
                  onChange={handleChange}
                  numInputs={6}
                  shouldAutoFocus='true'
                  className='otp-box'
                  isInputNum='true'
                  renderInput={(props) => <input {...props} />}
                  inputStyle={{
                    width: '37px',
                    height: '50px',
                    margin: '0 1rem',
                    fontSize: '2rem',
                    borderRadius: '8px',
                    border: '1px solid #5A50FE',
                    focus: 'focus',
                    backgroundColor: '#fff',
                  }}
                />
              </div>

              <div>
                <Button
                  className='phone-btn'
                  loading={verifyotp.loading}
                  onClick={otpBtnClick}
                  style={{
                    backgroundColor: otp.length >= 6 ? '#5a50fe' : '#e2e0ff',
                  }}
                >
                  Continue
                </Button>
              </div>
              <div className='resend'>
                <span>Didn't get code?</span>
                <button onClick={resendOtp}>Resend OTP</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
