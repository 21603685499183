import Joi from "joi";
import _ from "lodash";

const schema = Joi.object({
  REACT_APP_API_BASE_URL: Joi.string().required(),
  REACT_APP_MOMO_ID: Joi.string().required(),
  REACT_APP_BANK_ID: Joi.string().required(),
});

const { error, value } = schema.validate(
  _.pick(process.env, [
    "REACT_APP_API_BASE_URL",
    "REACT_APP_MOMO_ID",
    "REACT_APP_BANK_ID",
  ])
);

if (error) {
  throw error;
}

export const API = {
  baseURL: value.REACT_APP_API_BASE_URL,
  momoId: value.REACT_APP_MOMO_ID,
  bankId: value.REACT_APP_BANK_ID,
};
