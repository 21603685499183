//Required imports
import { Link } from "react-router-dom";
import { Button } from "../Button/Button";
import { Header } from "../Header/Header";
import Tic from "../../assets/Tic.png";
import { useSelector } from "react-redux";

//Styling
import "./Complete.css";

export const Complete = () => {
  //Get state from store
  const { recipientDetails } = useSelector((state) => state.recipient);
  const sender = useSelector((state) => state.sender);

  const message =
    recipientDetails && sender.senderDetails
      ? `You have paid  ${sender.currency} ${sender.senderDetails.amount} to ${
          recipientDetails.firstName + " " + recipientDetails.lastName
        } via Tag.`
      : "";
  return (
    <div>
      <Header />

      <div className="page">
        <div className="container">
          <div className="profile success">
            <div className="success-top">
              <img src={Tic} alt="" />
              <h3>Transaction completed</h3>
              <p>{message}</p>
            </div>

            <div>
              <a href="https://eversend.co">
                <Button btncontent="Go to website" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
