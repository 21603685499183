import QrCode from "../../assets/qr.png";
import "./Qr.css";

export const Qr = ({ qr }) => {
  return (
    <div className="qr">
      <img src={qr || QrCode} alt="qr code" />
    </div>
  );
};
