// Required imports
import { useEffect, useState } from "react";
import { Header } from "../Header/Header";
import { BsArrowLeft } from "react-icons/bs";
import { ProfileIntro } from "../ProfileIntro/ProfileIntro";
import { Divider } from "../Divider/Divider";
import { Qr } from "../Qr/Qr";
import { Footer } from "../Footer/Footer";
import { useNavigate } from "react-router-dom";
import { Error } from "../Amount/Amount";
import { useDispatch, useSelector } from "react-redux";
import { addPhoneNumber, clearOtpError, clearPhoneNumber, sendOtp } from "../../redux/sender/senderSlice";
import { Input, Space, Button, Radio, Select } from "antd";
import WhatsApp from "../../assets/whatsapp.png";
import Text from "../../assets/text.png";

// Styling
import "react-phone-input-2/lib/style.css";
import "./PhoneNumber.css";
import { parsePhoneNumberFromString } from "libphonenumber-js";

export const PhoneNumber = () => {
  // State
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberPrefix, setPhoneNumberPrefix] = useState("");
  const [err, setErr] = useState("");
  const [otpMethod, setOtpMethod] = useState("");
  const recipient = useSelector((state) => state.recipient);

  const sender = useSelector((state) => state.sender);
  const dispatch = useDispatch();

  const { Option } = Select;

  // Navigation
  const navigate = useNavigate();

  // Handle change for phone number input
  const handlePhone = (e) => {
    // First, remove all spaces
    let value = e.target.value.replace(/\s/g, "");
    // Then remove leading zeros
    value = value.replace(/^0+/, "");
    setPhoneNumber(value);
  };

  // Handle change for OTP method selection
  const handleOtpMethod = (e) => {
    setOtpMethod(e.target.value);
  };

  // Extract phone code, parse phone number, and remove leading zeros
  const countryCode = recipient.recipientDetails
    ? phoneNumberPrefix ||
      recipient.recipientDetails.wallets.filter((filteredWall) => filteredWall.currency === sender.currency)[0]
        .phoneCode[0]
    : "";
  const unParsedPhoneNumber = countryCode + phoneNumber;
  const parsedPhoneNumber = parsePhoneNumberFromString(unParsedPhoneNumber);
  const phone = parsedPhoneNumber ? parsedPhoneNumber.number.trim().replace(/^0+/, "").replace(/\s+/g, "") : "";
  const countryPhone = phoneNumber ? phoneNumber.replace(/^0+/, "").replace(/\s+/g, "") : "";

  // Build the OTP input object
  const username = recipient.recipientDetails ? recipient.recipientDetails.email : "";
  const otpInput = {
    phoneNumber: phone,
    username,
    phone: sender.currency === "XOF" ? `0${countryPhone}` : countryPhone,
    phonePrefix: countryCode,
    channel: otpMethod,
  };

  // Handle OTP button click
  const otpBtnClick = (e) => {
    e.preventDefault();
    dispatch(addPhoneNumber(phone));
    dispatch(sendOtp(otpInput));
  };

  //Handle phone number prefix change

  const handlePhoneNumberPrefixChange = (value) => {
    setPhoneNumberPrefix(value);
  };

  // Effect to handle navigation based on OTP status
  useEffect(() => {
    if (sender.otpDetails) {
      if (Object.values(sender.otpDetails).includes("approved")) {
        navigate(`/${recipient.recipientDetails.tag}/pay/confirm`);
      } else {
        navigate(`/${recipient.recipientDetails.tag}/pay/otp`);
      }
    }
  }, [sender.otpDetails, navigate, recipient.recipientDetails.tag]);

  // Effect to handle errors and clear them after a timeout
  useEffect(() => {
    if (sender?.error) {
      setErr("Otp not sent!");
    }

    const timeout = setTimeout(() => {
      setErr("");
      dispatch(clearOtpError());
      dispatch(clearPhoneNumber());
    }, 3000);

    // Cleanup the timeout when the component unmounts
    return () => {
      clearTimeout(timeout);
    };
  }, [dispatch, sender?.error]);

  // Render the component
  return (
    <div>
      <Header />

      <div className="page">
        <div className="container">
          <div>
            <div
              className="back"
              onClick={() => {
                navigate(-1);
              }}
            >
              <BsArrowLeft style={{ color: "#4B487A", fontSize: "20px" }} />
              <span>Back</span>
            </div>

            <div className="profile">
              <div className="profile-container">
                {recipient.recipientDetails && (
                  <ProfileIntro
                    avatar={recipient.recipientDetails.avatarURL}
                    recipient={recipient.recipientDetails.firstName + " " + recipient.recipientDetails.lastName}
                    tag={recipient.fulltag}
                  />
                )}
              </div>

              <div className="amount phone">
                <form>
                  <label className="phone-description">
                    Enter your phone number and select how you want to receive your OTP. <br /> We'll debit your account
                    from this number.
                  </label>

                  {sender &&
                    recipient.recipientDetails.wallets
                      .filter((filteredWall) => filteredWall.currency === sender.currency)
                      .map((wallet) => (
                        <Space direction="vertical" key={wallet.phoneCode} className="phone-space-item">
                          {wallet.phoneCode.length > 1 ? (
                            <Input
                              addonBefore={
                                <Select
                                  style={{ width: "125px" }}
                                  name="phoneNumberPrefix"
                                  value={phoneNumberPrefix}
                                  onChange={handlePhoneNumberPrefixChange}
                                  defaultValue={wallet.phoneCode[0]}
                                  defaultOpen={true}
                                >
                                  {recipient.recipientDetails &&
                                    recipient.recipientDetails.wallets.map((wallet) =>
                                      wallet.phoneCode.length !== 0 ? (
                                        <Option key={wallet.currency} value={wallet.phoneCode[0]} placeholder="erer">
                                          {`${wallet.currency} (${wallet.phoneCode[0]})`}
                                        </Option>
                                      ) : null
                                    )}
                                </Select>
                              }
                              placeholder="000-0000"
                              name="pnumber"
                              onChange={handlePhone}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              inputMode="numeric"
                            />
                          ) : (
                            <Input
                              addonBefore={`${sender.currency.substring(0, 2)} (${wallet.phoneCode[0]})`}
                              placeholder="000-0000"
                              onChange={handlePhone}
                              inputMode="numeric"
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                          )}
                          <Radio.Group className="otp-method" onChange={handleOtpMethod}>
                            <Radio.Button className="otp-method-whatsapp" value="whatsapp">
                              <img src={WhatsApp} alt="whatsapp icon" />
                              WhatsApp
                            </Radio.Button>
                            <Radio.Button className="otp-method-text" value="sms">
                              <img src={Text} alt="text icon" />
                              <span> Text message </span>
                            </Radio.Button>
                          </Radio.Group>
                        </Space>
                      ))}

                  {err ? <Error err={err} /> : ""}

                  <Button
                    className="phone-btn"
                    loading={sender.loading}
                    onClick={otpBtnClick}
                    style={{
                      backgroundColor: phoneNumber ? "#5a50fe" : "#e2e0ff",
                    }}
                  >
                    Continue
                  </Button>
                </form>
              </div>

              <div className="divider-container">
                <Divider />
              </div>

              <div className="qr-container">
                {recipient.recipientDetails && <Qr qr={recipient.recipientDetails.qrCodeUrl} />}
              </div>

              <div>
                <Footer />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
