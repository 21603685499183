//Required imports
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Header } from "../Header/Header";
import Load from "../../assets/load.png";
import Alert from "../../assets/alert.png";
import { checkPaymentStatus } from "../../redux/paying/payingSlice";

//Styling
import "./Paying.css";
import { Spin } from "antd";

export const Paying = () => {
  const { recipientDetails } = useSelector((state) => state.recipient);
  const { payingDetails } = useSelector((state) => state.paying);
  const { momoLoadDetails } = useSelector((state) => state.loadmomo);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const input = {
    username: recipientDetails.email,
    loadReference: momoLoadDetails.reference,
  };

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(checkPaymentStatus(input));
    }, 30000);

    const timeout = setTimeout(() => {
      clearInterval(interval);
      navigate(`/${recipientDetails.tag}/pay/pending`);
    }, 300000);

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    if (payingDetails?.toppedUp) {
      navigate(`/${recipientDetails.tag}/pay/complete`);
    }
  }, [payingDetails]);

  return (
    <div>
      <Header />
      <div className="page">
        <div className="container">
          <div className="profile paying">
            <div className="paying-first">
              <div className="paying-top">
                <img src={Load} alt="" />
                {recipientDetails && (
                  <h3>
                    Your payment to {recipientDetails.firstName} {recipientDetails.lastName} has been initiated
                  </h3>
                )}
              </div>

              <div className="paying-center">
                <div>
                  <img src={Alert} alt="" />
                </div>
                <div>
                  <p>We sent a prompt to your mobile money number, go to your phone to complete transaction.</p>
                  <p>
                    Transaction might take <b>10 minutes</b> to be delivered.
                  </p>
                </div>
              </div>
            </div>

            <div className="spinner">
              <Spin size="large" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
