import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API } from "../../firebase/firebase";

const initialState = {
  loading: false,
  verificationDetails: null,
  error: "",
};

export const verifyOtp = createAsyncThunk(
  "otp/verifyOtp",
  async (verifyOtpInput) => {
    const { username, pinId, pin } = verifyOtpInput;
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ username, pinId, pin }),
    };

    try {
      const res = await fetch(`${API.baseURL}/verifyOtp`, requestOptions);
      if (!res.ok) {
        throw new Error(res.statusText);
      }
      const data = await res.json();
      return data.result;
    } catch (err) {
      throw err;
    }
  }
);

const otpSlice = createSlice({
  name: "verifyotp",
  initialState,
  reducers: {
    clearVerificationDetails: () => initialState,

    clearVerificationError: (state) => {
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(verifyOtp.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(verifyOtp.fulfilled, (state, action) => {
      state.loading = false;
      state.verificationDetails = action.payload;
      state.error = "";
    });
    builder.addCase(verifyOtp.rejected, (state, action) => {
      state.loading = false;
      state.verificationDetails = null;
      state.error = action.error.name;
    });
  },
});
export const { clearVerificationDetails, clearVerificationError } =
  otpSlice.actions;
export default otpSlice.reducer;
