import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import recipientReducer from '../recipient/recipientSlice';
import chargesRecucer from '../charges/chargesSlice';
import senderReducer from '../sender/senderSlice';
import verifyOtpReducer from '../otp/otpSlice';
import confirmMomoReducer from '../transaction/transactionSlice';
import loadBankReducer from '../bank/bankSlice';
import payingReducer from '../paying/payingSlice';
import otpConfirmationReducer from '../otp-confirmation/otpConfirmationSlice';

//Redux persist
const rootReducer = combineReducers({
  recipient: recipientReducer,
  charges: chargesRecucer,
  sender: senderReducer,
  verifyotp: verifyOtpReducer,
  loadmomo: confirmMomoReducer,
  bank: loadBankReducer,
  paying: payingReducer,
  otpconfirmation: otpConfirmationReducer,
});

const persistConfig = {
  key: 'root',
  storage,
};

export const PersistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: PersistedReducer,
  devTools: false,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

export const resetStore = async () => {
  await persistor.purge();
  store.dispatch(resetStore());
  await persistor.flush();
};
