import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../firebase/firebase";

const initialState = {
  loading: false,
  payingDetails: null,
  error: "",
};

export const checkPaymentStatus = createAsyncThunk(
  "wallet/checkStatus",
  async (input) => {
    const { username, loadReference, accountReference } = input;

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        username,
        loadReference,
        accountReference,
      }),
    };

    try {
      const res = await fetch(`${API.baseURL}/checkPayment`, requestOptions);
      if (!res.ok) {
        throw new Error(res.statusText);
      }
      const data = await res.json();
      return data.data;
    } catch (err) {
      throw err;
    }
  }
);

const payingSlice = createSlice({
  name: "paying",
  initialState,
  reducers: {
    clearPayingDetails: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(checkPaymentStatus.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(checkPaymentStatus.fulfilled, (state, action) => {
      state.loading = false;
      state.payingDetails = action.payload;
      state.error = "";
    });
    builder.addCase(checkPaymentStatus.rejected, (state, action) => {
      state.loading = false;
      state.payingDetails = null;
      state.error = action.error.message;
    });
  },
});

export const { clearPayingDetails } = payingSlice.actions;

export default payingSlice.reducer;
