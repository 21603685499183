import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../firebase/firebase";

const initialState = {
  loading: false,
  recipientDetails: null,
  error: "",
  fulltag: "",
};

export const fetchRecipientDetails = createAsyncThunk(
  "recipient/fetchDetails",
  async (tag) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ tag }),
    };

    try {
      const res = await fetch(`${API.baseURL}/getUserDetails`, requestOptions);
      if (!res.ok) {
        throw new Error(res.statusText);
      }

      const data = await res.json();
      return data.data;
    } catch (err) {
      throw err;
    }
  }
);

const recipientDetailsSlice = createSlice({
  name: "receipient",
  initialState,
  reducers: {
    addFullTag: (state, action) => {
      state.fulltag = action.payload;
    },
    clearRecipientDetails: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRecipientDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchRecipientDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.recipientDetails = action.payload;
      state.error = "";
    });
    builder.addCase(fetchRecipientDetails.rejected, (state, action) => {
      state.loading = false;
      state.recipientDetails = null;
      state.error = action.error.name;
    });
  },
});

export const { addFullTag, clearRecipientDetails } =
  recipientDetailsSlice.actions;
export default recipientDetailsSlice.reducer;
