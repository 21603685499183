import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../firebase/firebase";

const initialState = {
  loading: false,
  chargesDetails: null,
  error: "",
};

export const fetchCharges = createAsyncThunk(
  "charges/fetchCharges",
  async (chargesInput) => {
    const { username, paymentMethodId, currency, amount } = chargesInput;
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        username,
        amount,
        paymentMethodId,
        currency,
      }),
    };

    try {
      const res = await fetch(`${API.baseURL}/charges`, requestOptions);
      if (!res.ok) {
        throw new Error(res.statusText);
      }
      const data = await res.json();
      return data.data;
    } catch (err) {
      throw err;
    }
  }
);

const chargesDetailsSlice = createSlice({
  name: "receipient",
  initialState,
  reducers: {
    clearCharges: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCharges.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchCharges.fulfilled, (state, action) => {
      state.loading = false;
      state.chargesDetails = action.payload;
      state.error = "";
    });
    builder.addCase(fetchCharges.rejected, (state, action) => {
      state.loading = false;
      state.chargesDetails = null;
      state.error = action.error.message;
    });
  },
});

export const { clearCharges } = chargesDetailsSlice.actions;

export default chargesDetailsSlice.reducer;
