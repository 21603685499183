import "./Button.css";
export const Button = ({ btncontent, style, onClick, id }) => {
  return (
    <div className="btn-container">
      <button style={style} onClick={onClick} id={id}>
        {btncontent}
      </button>
    </div>
  );
};
