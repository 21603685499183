//Required imports
import Logo from "../../assets/logo.png";

//Styling
import "./Header.css";

export const Header = () => {
  return (
    <div className="header">
      <div className="container">
        <div>
          <a href="https://eversend.co" target="_blank" rel="noreferrer">
            <img src={Logo} alt="eversend logo" />
          </a>
        </div>
      </div>
    </div>
  );
};
