import { useState, useEffect } from 'react';
import OTPInput from 'react-otp-input';
import { Header } from '../Header/Header';
import { BsArrowLeft } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { Button, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearOtpConfirmationDetails,
  clearOtpConfirmationError,
  confirmOtp,
} from '../../redux/otp-confirmation/otpConfirmationSlice';

export const OtpConfirmation = () => {
  const [otp, setOtp] = useState('');
  const loadmomo = useSelector((state) => state.loadmomo);
  const otpconfirmation = useSelector((state) => state.otpconfirmation);
  const recipient = useSelector((state) => state.recipient);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //Handle change
  const handleChange = (otp) => {
    setOtp(otp);
  };

  //Back btn logic

  const goBack = () => {
    dispatch(clearOtpConfirmationDetails());
    navigate(`/${recipient.recipientDetails.tag}/pay/confirm`);
  };

  //Otp btn logic

  const otpBtnClick = (e) => {
    e.preventDefault();
    const verifyOtpInput = {
      transaction_id: loadmomo.momoLoadDetails.transaction_id,
      otp: otp,
    };
    dispatch(confirmOtp(verifyOtpInput));
  };

  // Check if the OTP is confirmed and navigate to the next page
  useEffect(() => {
    if (otpconfirmation.otpConfirmationDetails) {
      navigate(`/${recipient.recipientDetails.tag}/pay/paying`);
    }
  }, [otpconfirmation.otpConfirmationDetails, navigate, recipient.recipientDetails.tag]);

  // If the OTP is not confirmed, show an error message
  useEffect(() => {
    if (otpconfirmation.error !== '') {
      setOtp('');
      message.error('Wrong otp entered! Please try again.');
      dispatch(clearOtpConfirmationError());
    }
  }, [dispatch, otpconfirmation.error, otpconfirmation.otpConfirmationError]);

  return (
    <div>
      <Header />
      <div className='page'>
        <div className='container'>
          <div>
            <div className='otp'>
              <h3>Enter OTP</h3>
              <p>To confirm this transaction. Enter the OTP code we sent to your phone number.</p>

              <div className='otp-input'>
                <OTPInput
                  value={otp}
                  onChange={handleChange}
                  numInputs={6}
                  shouldAutoFocus='true'
                  className='otp-box'
                  isInputNum='true'
                  renderInput={(props) => <input {...props} />}
                  inputStyle={{
                    width: '37px',
                    height: '50px',
                    margin: '0 1rem',
                    fontSize: '2rem',
                    borderRadius: '8px',
                    border: '1px solid #5A50FE',
                    focus: 'focus',
                    backgroundColor: '#fff',
                  }}
                />
              </div>

              <div>
                <Button
                  className='phone-btn'
                  onClick={otpBtnClick}
                  loading={otpconfirmation.loading}
                  style={{
                    backgroundColor: otp.length >= 6 ? '#5a50fe' : '#e2e0ff',
                  }}
                >
                  Confirm transaction and pay
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
