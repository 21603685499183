//Required imports
import { useEffect } from 'react';
import { Header } from '../Header/Header';
import { BsArrowLeft } from 'react-icons/bs';
import { Footer } from '../Footer/Footer';
import { useNavigate } from 'react-router-dom';
import { API } from '../../firebase/firebase';
import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { loadMomoWallet } from '../../redux/transaction/transactionSlice';
import { clearVerificationDetails } from '../../redux/otp/otpSlice';

//styling
import './Confirm.css';
import { clearOtpDetails } from '../../redux/sender/senderSlice';

export const Confirm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const recipient = useSelector((state) => state.recipient);
  const sender = useSelector((state) => state.sender);
  const charge = useSelector((state) => state.charges);
  const loadmomo = useSelector((state) => state.loadmomo);
  const currenciesWithRedirect = ['XOF'];

  const username = recipient.recipientDetails ? recipient.recipientDetails.email : '';

  const paymentMethodId = recipient.recipientDetails
    ? recipient.recipientDetails.momoWallets.includes(sender.currency)
      ? API.momoId
      : API.bankId
    : '';

  const redirectUrl =
    sender.currency === 'GHS' || sender.currency === 'XOF'
      ? `https://eversend.me/${recipient.recipientDetails.tag}/pay/paying`
      : '';

  const loadBtnClick = (e) => {
    e.preventDefault();
    const loadInput = {
      username,
      amount: parseFloat(sender.senderDetails.amount),
      paymentMethodId,
      currency: sender.currency,
      pinId: sender.otpDetails.pinId,
      senderFirstName: sender.senderDetails.firstName,
      senderLastName: sender.senderDetails.lastName,
      redirectUrl: redirectUrl || `/${recipient.recipientDetails.tag}/pay/paying`,
      note: sender.senderDetails.note,
    };

    dispatch(loadMomoWallet(loadInput));
  };

  useEffect(() => {
    if (loadmomo.momoLoadDetails) {
      const otpUrl = loadmomo.momoLoadDetails.otpUrl || `/${recipient.recipientDetails.tag}/pay/paying`;
      if (currenciesWithRedirect.includes(sender.currency)) {
        window.location.href = otpUrl;
      } else if (sender.currency === 'GHS') {
        navigate(`/${recipient.recipientDetails.tag}/pay/otp-confirmation`);
      } else {
        navigate(otpUrl);
      }
    }
  }, [loadmomo.momoLoadDetails]);

  //Go back

  const goBack = () => {
    if (Object.values(sender.otpDetails).includes('approved')) {
      dispatch(clearOtpDetails());
      navigate(-1);
    } else {
      dispatch(clearVerificationDetails());
      navigate(-1);
    }
  };

  return (
    <div>
      <Header />

      <div className='page'>
        <div className='container'>
          <div>
            <div className='back' onClick={goBack}>
              <BsArrowLeft style={{ color: '#4B487A', fontSize: '20px' }} />
              <span>Back</span>
            </div>

            <div className='profile confirm-top'>
              <h3>Confirm transaction</h3>
            </div>

            {recipient.recipientDetails && (
              <div className='confirm-container'>
                <div className='profile confirm'>
                  <div>
                    <p>Account name</p>
                    <p>{recipient.recipientDetails.firstName + ' ' + recipient.recipientDetails.lastName}</p>
                  </div>

                  <div>
                    <p>Eversend tag</p>
                    <p>{recipient.fulltag}</p>
                  </div>

                  <div>
                    <p>Receiving wallet</p>
                    <p>{sender.currency}</p>
                  </div>

                  <div>
                    <p>Transaction amount</p>
                    <p> {sender.currency + ' ' + sender.senderDetails.amount}</p>
                  </div>

                  {charge.chargesDetails && (
                    <div>
                      <p>Transaction cost</p>
                      <p>{sender.currency + ' ' + charge.chargesDetails.charges}</p>
                    </div>
                  )}

                  {charge.chargesDetails && (
                    <div>
                      <p>Total amount</p>
                      <p>
                        {sender.currency + ' '}
                        {parseInt(sender.senderDetails.amount) + Math.ceil(charge.chargesDetails.charges)}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            )}

            <div className='profile transaction-btn'>
              <div>
                <Button onClick={loadBtnClick} loading={loadmomo.loading}>
                  Make transaction
                </Button>
                <Button style={{ cursor: 'pointer' }} btncontent='Make transaction' />
              </div>

              <div className='confirm-footer'>
                <Footer />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
