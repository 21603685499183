import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../firebase/firebase';

const initialState = {
  loading: false,
  otpConfirmationDetails: null,
  error: '',
};

export const confirmOtp = createAsyncThunk('otpConfirmation/confirmOtp', async (confirmOtpInput) => {
  const { transaction_id, otp } = confirmOtpInput;
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ transaction_id, otp }),
  };

  try {
    const res = await fetch(`${API.baseURL}/transactionOtp`, requestOptions);
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    const data = await res.json();
    return data;
  } catch (err) {
    throw err;
  }
});

const otpConfirmationSlice = createSlice({
  name: 'otpConfirmation',
  initialState,
  reducers: {
    clearOtpConfirmationDetails: () => initialState,
    clearOtpConfirmationError: (state) => {
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(confirmOtp.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(confirmOtp.fulfilled, (state, action) => {
      state.loading = false;
      state.otpConfirmationDetails = action.payload;
      state.error = '';
    });
    builder.addCase(confirmOtp.rejected, (state, action) => {
      state.loading = false;
      state.otpConfirmationDetails = null;
      state.error = action.error.name;
    });
  },
});

export const { clearOtpConfirmationDetails, clearOtpConfirmationError } = otpConfirmationSlice.actions;

export default otpConfirmationSlice.reducer;
