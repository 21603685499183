//Required imports
import { BsDot } from "react-icons/bs";

//Styling
import "./Footer.css";

export const Footer = () => {
  return (
    <div className="footer">
      <a href="https://eversend.co">&copy; Eversend</a>
      <BsDot style={{ color: "#55555e" }} />
      <a href="mailto: support@eversend.co">Contact</a>
      <BsDot style={{ color: "#55555e" }} />
      <a
        href="https://eversend.co/terms-of-service"
        target="_blank"
        rel="noreferrer"
      >
        Privacy & Terms
      </a>
    </div>
  );
};
