//Styling
import { Header } from "../Header/Header";
import "./Home.css";
import ArrowRight from "../../assets/arrow-right.png";

export const Home = () => {
  return (
    <div>
      <Header />

      <div className="home">
        <div>
          <div></div>
          <div></div>
        </div>

        <div>
          <div className="container">
            <div>
              <div className="hero-left">
                <div className="hero-left-first">
                  <p>Eversend Tags</p>
                  <h3>Help others know it's you they are paying.</h3>
                  <p>
                    Create your Eversend.Me link, share it, and start receiving
                    money. Accept payment from friends, customers. Anyone can
                    use it.
                  </p>
                </div>

                <a
                  href="https://eversend.page.link/create-tag"
                  className="hero-left-last"
                >
                  <span> Create my Eversend.me link</span>
                  <img src={ArrowRight} alt="arrow right" />
                </a>
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
