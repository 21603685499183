import { Link } from "react-router-dom";
import { Button } from "../Button/Button";
import { Header } from "../Header/Header";
import { ProfileIntro } from "../ProfileIntro/ProfileIntro";
import Avatar from "../../assets/avatar.webp";
import "./Unsupported.css";
import { useSelector } from "react-redux";

export const Unsupported = () => {
  const recipient = useSelector((state) => state.recipient);

  return (
    <div>
      <Header />

      <div className="page">
        <div className="container">
          <div className="profile unsupported">
            <div className="profile-container">
              {recipient.recipientDetails ? (
                <ProfileIntro
                  avatar={recipient.recipientDetails.avatarURL}
                  recipient={
                    recipient.recipientDetails.firstName +
                    " " +
                    recipient.recipientDetails.lastName
                  }
                  tag={recipient.fulltag}
                />
              ) : (
                <ProfileIntro
                  avatar={Avatar}
                  recipient="Eversend User"
                  tag="https://eversend.me/hah"
                />
              )}
            </div>
            <div className="unsupported-content">
              <h3>Cannot recieve payment</h3>

              <p>
                Unfortunately this users account is in a region that cannot
                recieve payment via Eversend tags.
              </p>

              <p>
                You can download the app to do an Eversend to Evesend transfer.
              </p>
            </div>

            <div>
              <a href="https://eversend.co" target="_blank" rel="noreferrer">
                <Button
                  btncontent="Download Eversend app"
                  style={{ cursor: "pointer" }}
                />
              </a>
            </div>

            <div className="unsupported-link">
              <Link to="/pay/createtransaction">
                Create another tag transaction
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
